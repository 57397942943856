import { Loading } from 'element-ui'
import { resetRouter, addDynamicRoutes } from '@/router'
import { getFileBlobUrl } from '@/services/modules/comm.js'
import { openBlobUrlInNewTab } from '@/utils'

export default {
  data () {
    return {
      previewVisible: false,
      previewData: {},
      currentInvoice: {},
      conditions: [],
      queryVisible: false,
      // 获取邮箱验证码loading
      emailCodeLoading: false,
      // 获取邮箱验证码的倒计时
      emailCodeCountDown: 0,
      pricingTierEnabled: false,
    }
  },
  methods: {
    getFileBlobUrl,
    openBlobUrlInNewTab,
    /**
     * 格式化货币
     */
    formatCurrency (cellValue) {
      try {
        return cellValue.toLocaleString('ja-JP', {
          style: 'currency',
          currency: 'JPY'
        })
      } catch {
        return cellValue
      }
    },

    /**
     * 格式化日期
     */
    formatDateMixin (row, column, cellValue) {
      if (cellValue) {
        const dateStr = cellValue.replace(/-/g, '/')
        const date = new Date(dateStr)
        const year = date.getFullYear()
        const month = (date.getMonth() + 1).toString().padStart(2, '0')
        const day = date.getDate().toString().padStart(2, '0')
        return `${year}-${month}-${day}`
      }
      return cellValue
    },

    /**
     * 格式化日期
     */
    formatDateTimeMixin ( date ) {
      if (date) {
        return this.$moment(date).format('YYYY-MM-DD HH:mm:ss')
      }
      return date
    },

    /**
     * 打开通用搜索弹窗
     */
    openCommonQueryMixin () {
      this.queryVisible = true
    },

    /**
     * 监听通用搜索弹窗关闭
     */
    onCommonQueryCloseMixin () {
      this.queryVisible = false
    },

    /**
     * 监听通用搜索上报的搜索条件
     * @param {Array} conditions:搜索条件对象组成的数组
     */
    onReportConditionMixin (conditions) {
      this.conditions = conditions
      const conditionsStr = encodeURIComponent(JSON.stringify(conditions))
      const url = `${this.url}?conditions=${conditionsStr}`
      this.getTableDataBusinessMixin(url)
    },

    /**
     * 下载文件
     * @param {Object} file:文件数据对象
     */
    downloadFileMixin (file) {
      return new Promise((resolve, reject) => {
        this.$request.getFile({
          url: this.$apis.downloadFile,
          data: [file.id]
        }).then(res => {
          try {
            const fileName = file.originalName || file.name
            const list = fileName.split('.')
            const suffix = list[list.length - 1].toLowerCase()
            const pictureToMIME = {
              png: 'image/png',
              jpg: 'image/jpeg',
              pdf: 'application/pdf',
              doc: 'application/msword',
              docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
              xls: 'application/vnd.ms-excel',
              xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            }
            const type = pictureToMIME[suffix]
            const blob = new Blob([res], { type })
            const url = URL.createObjectURL(blob)
            resolve(url)
          } catch (e) {
            reject(new Error())
          }
        })
      })
    },

    downloadMultipleFileMixin (files) {
      return new Promise((resolve, reject) => {
        const data = files.map(d => d.id)
        this.$request.post({
          url: this.$apis.getFileUrl,
          data: data
        }).then(res => {
          if (res?.code === 1000) {
            const url = res.data
            resolve(url)
          }
        })
      })
    },
    /**
     * 预览文件
     * @param {String} fileId:文件id
     */
    previewFileMixin (fileId) {
      this.getFileBlobUrl(fileId).then(url => {
        openBlobUrlInNewTab(url)
      }).catch(e => {
        this.loading = false
        this.error = true
      })
    },

    /**
     * 批量/删除文件
     * @param {Array} fileIds:文件id组成的数组
     */
    deleteFileMixin (fileIds, isGetTableData = true) {
      this.$confirm(this.$t('deletePermanent'), this.$t('deleteFile'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning'
      }).then(() => {
        const loading = Loading.service()
        const fileIdList = Array.isArray(fileIds) ? fileIds : [fileIds]
        this.$request.post({
          url: this.$apis.deleteFile,
          data: fileIdList
        }).then(res => {
          if (res?.code === 1000) {
            isGetTableData && this.getTableDataMixin()
            this.$message.success(this.$t('fileDeleteSuccess'))
          }
        }).finally(() => {
          loading.close()
        })
      }).catch(() => {
      })
    },
    /**
     * 删除单个文件，没有tip
     * @param {Array} fileIds:文件id组成的数组
     */
    deleteSingleFileMixin (fileId, cb) {
      this.$request.post({
        url: this.$apis.deleteFile,
        data: [fileId]
      }).then(res => {
        if (res?.code === 1000) {
          this.$message.success(this.$t('fileDeleteSuccess'))
        }
      }).finally(() => {
        if (cb) {
          cb()
        }
      })
    },

    /**
     * 获取邮箱验证码
     * @param {String} email:电子邮箱地址
     */
    getEmailCode (email) {
      return new Promise((resolve, reject) => {
        this.emailCodeLoading = true
        this.$request.post({
          url: this.$apis.sendEmail,
          data: {
            email
          }
        }).then(res => {
          if (res?.code === 1000) {
            this.$message.success(this.$t('codeObtainedSuccessfully'))
            this.emailCodeCountDown = 60
            const timer = setInterval(() => {
              this.emailCodeCountDown--
              if (this.emailCodeCountDown <= 0) {
                clearInterval(timer)
              }
            }, 1000)
            resolve(res.data.id)
          }
        }).finally(() => {
          this.emailCodeLoading = false
        })
      })
    },

    getEmailCodeInternal (email) {
      return new Promise((resolve, reject) => {
        this.emailCodeLoading = true
        this.$request.post({
          url: this.$apis.sendEmailInternal,
          data: {
            email
          }
        }).then(res => {
          if (res?.code === 1000) {
            this.$message.success('An email will be sent if the user exists within the system')
            this.emailCodeCountDown = 60
            const timer = setInterval(() => {
              this.emailCodeCountDown--
              if (this.emailCodeCountDown <= 0) {
                clearInterval(timer)
              }
            }, 1000)
            resolve(res.data.id)
          }
        }).finally(() => {
          this.emailCodeLoading = false
        })
      })
    },

    /**
     * 刷新导航标签数据
     * @param {Array} menus:菜单数据数组对象
     */
    refreshNavigationTag (menus) {
      const allMenus = []
      // 获取非树结构菜单数据
      const getTabularMenu = (menus) => {
        menus.forEach(item => {
          if (item.children?.length) {
            getTabularMenu(item.children)
          } else if (item.enable) {
            allMenus.push(item)
          }
        })
      }
      getTabularMenu(menus)
      const navigationTags = this.$store.state.navigationTags.filter(tag => {
        // 筛选出包含在非树结构菜单中的导航标签
        return allMenus.some(menu => menu.name === tag.name)
      })
      // 更新导航标签
      this.$store.commit('setNavigationTags', navigationTags)
    },

    /**
     * 根据用户获取菜单权限
     * @param {String} userId:用户ID
     * @param {String} orgId:企业ID
     * @param {String} from:操作来源，login来源于登录，switch来源于企业切换
     * @param {Boolean} isShip:是否是船登录
     */
    getMenusAndFunctionsByUser (userId, orgId, from = 'login', isShip = false) {
      return new Promise(resolve => {
        this.$request.post({
          url: this.$apis.queryMenuTree,
          // url: from === 'login' ? this.$apis.queryMenuTreeMenu : this.$apis.queryMenuTree,
          data: {
            userId,
            orgId
          }
        }).then(res => {
          if (res?.code === 1000) {
            const dashboard = {
              "component": "dashboard",
              "icon": "el-icon-picture-outline-round",
              "name": "Dashboard",
              "router": "dashboard",
              "children": null,
              "functions": null,
              "enable": true,
              "sort": 0,
            }
            const menuData = [ dashboard, ...(res.data || []) ]
            const setChildren = data => {
              data.forEach(item => {
                if (item.children?.length) {
                  setChildren(item.children)
                } else {
                  item.children = []
                }
              })
            }
            
            setChildren(menuData)
            
            // 更新导航状态
            this.$store.commit('setMenus', menuData)
            // 刷新导航标签数据
            this.refreshNavigationTag(menuData)
            // 添加动态路由
            addDynamicRoutes()
            if (from === 'login') {
              this.$router.push({ path: '/admin/dashboard' })
            } else {
              // 扁平化菜单
              const newMenus = []
              const flatMenus = (menus) => {
                menus.forEach(menu => {
                  const { children } = menu
                  if (children.length) {
                    flatMenus(children)
                  } else {
                    newMenus.push(menu)
                  }
                })
              }
              flatMenus(menuData)
              // 检测新菜单中是否包含当前路由
              const hasCurrentRoute = newMenus.some(item => item.name === this.$route.name)
              if (hasCurrentRoute) {
                // 包含当前路由则跳转到当前路由
                const { currentCompany } = this.$store.state
                this.$router.push({
                  name: this.$route.name,
                  params: { companyId: currentCompany.id }
                })
              } else {
                // 没包含当前路由则重定向到首页
                this.$store.commit('setNavigationTags', [])
                this.$router.push({
                  name: 'admin'
                })
              }
              // this.reload()
            }
            resolve()
          }
        })
      })
    },

    /**
     * 根据用户获取功能权限,不包括菜单权限
     * @param {String} userId:用户ID
     * @param {String} orgId:企业ID
     */
    getFunctionsByUser (userId, orgId, callBack = null) {
      this.$request.post({
        url: this.$apis.queryMenuTree,
        data: {
          userId,
          orgId
        }
      }).then(res => {
        if (res?.code === 1000) {
          const routeName = this.$route.name
          const data = res.data || []
          const flattenedMenus = []
          // 扁平化菜单数组数据,兼容多级菜单
          const flatChildren = menuData => {
            menuData.forEach(item => {
              const children = item.children || []
              if (children.length) {
                flatChildren(children)
              } else {
                flattenedMenus.push(item)
              }
            })
          }
          flatChildren(data)
          // 查找当前路由
          const currentMenu = flattenedMenus.find(item => item.name === routeName)
          if (currentMenu) {
            this.$route.meta.functions = currentMenu.functions
            this.pageVisible = false
            this.$nextTick(() => {
              this.pageVisible = true
            })
          }
          callBack && callBack()
        }
      })
    },

    /**
     * 校验各种字段值是否存在
     * @param {Object} reqData:请求体
     */
    validateDuplicateData (reqData) {
      return new Promise(resolve => {
        this.$request.post({
          url: this.$apis.validateDuplicateData,
          data: reqData
        }).then(res => {
          if (res?.code === 1000) {
            resolve(res.data)
          }
        })
      })
    },

    getCreditAssociations () {
      return new Promise(resolve => {
        this.$request.get({
          url: `${this.$apis.creditAssociations}?pageNumber=0&pageSize=9999`
        }).then(res => {
          if (res?.code === 1000) {
            this.pricingTierEnabled = res.data.pricingTierEnabled
            const creditAssociationList = res.data.content
            resolve(creditAssociationList)
          }
        })
      })
    },
    /**
     *  Is Credit Authorization Required
     */
    verifyCA (data) {
      return new Promise((resolve, reject) => {
        this.$request.post({
          url: this.$apis.verifyContractCA,
          data: data
        }).then(res => {
          if (res?.code === 1000) {
            const isCaRequired = res.data
            resolve({ isCaRequired })
          }
        }).catch(e => {
          reject(e)
        })
      })
    },
    /**
     * cdNote:Is Credit Authorization Required
     */
    verifyCdNoteCA (data) {
      return new Promise((resolve, reject) => {
        this.$request.post({
          url: this.$apis.verifyCdNoteCA,
          data: data
        }).then(res => {
          if (res?.code === 1000) {
            const isCaRequired = res.data
            resolve({ isCaRequired })
          }
        }).catch(e => {
          reject(e)
        })
      })
    },

    /**
     * set Pricing Tier Module: Get The Pricing Tier Definition
     */
    pricingTierDefinition() {
      return new Promise((resolve, reject) => {
        this.$request.get({
          url: this.$apis.pricingTierDef,
        }).then(res => {
          if (res?.code === 1000) {
            resolve( res?.data )
          }
        }).catch(e => {
          reject(e)
        })
      })
    },

    /**
     * set Pricing Tier Module: Get The Monthly Pricing Tier
     */
    pricingTierMonthly( yyyy, m , orgId) {
      const year = ( parseInt( yyyy ) ).toString()
      const month = ( parseInt( m ) ).toString()
      return new Promise((resolve, reject) => {
        this.$request.get({
          url: this.$apis.pricingTier+`/${orgId}/monthly/`+ year + '/' + month,
        }).then(res => {
          if (res?.code === 1000) {
            resolve(res?.data)
          }
        }).catch(e => {
          reject(e)
        })
      })
    },

    /**
     * set Pricing Tier Module: Get The Daily Pricing Tier
     */
    pricingTierDaily( date, orgId ) {
      date =  date ?? this.$moment().format('YYYY-MM-DD')
      const url = this.$apis.pricingTier + `/${orgId}/` + date
      return new Promise((resolve, reject) => {
        this.$request.get({
          url,
        }).then(res => {
          if (res?.code === 1000) {
            resolve(res?.data)
          }
        }).catch(e => {
          reject(e)
        })
      })
    },

    /**
     * set Pricing Tier Module: Get The Monthly Pricing Tier
     */
    submitPricingTier( data ) {
      return new Promise((resolve, reject) => {
        this.$request.post({
          url: this.$apis.pricingTierSubmitTiers,
          data: data
        }).then(res => {
          if (res?.code === 1000) {
            this.$message.success('Add/Update Pricing Tier Successfully')
          } else {
            this.$message.error('Add/Update Pricing Tier Fail')
          }
          resolve( res?.code === 1000 )
        }).catch(e => {
          reject(e)
        })
      })
    },

    /**
     * set Pricing Tier Module: Get Cash Pricing Tier
     */
    async cashPricingTier( date, orgId ) {

      date = this.$moment( date ?? new Date() ).format('YYYY-MM-DD')

      let marketPrice = 0
      let exchangeRate = 0
      let addMt = 0

      await this.pricingTierDaily(date,orgId).then( res => {
        let data = res.find(item => item.tierName == 'Tier 4')
        if( data ) {
          marketPrice = parseFloat(data.marketPrice)
          exchangeRate = parseFloat(data.exchangeRate)
          addMt = parseFloat(data.addMt)
        }
      })

      return this.calculatePricingTier(marketPrice, exchangeRate, addMt)
    },

    /**
     * set Pricing Tier Module: Calculate the Pricing Tier
     */
    calculatePricingTier(marketPrice, exchangeRate, addMt) {
      return ( (marketPrice * exchangeRate ) + addMt ).toFixed(4)
    },

    /**
     * set Customer Pricing Tier Module: Get Customer Pricing Tier
     */
    async customerPricingTier( sysOrganizationId ) {
      return new Promise((resolve, reject) => {
        this.$request.get({
          url: this.$apis.creditAssociationsCustomerTiers + `?pageSize=9999&&filter=sysOrganizationId:'${sysOrganizationId}'`,
        }).then(res => {
          if (res?.code === 1000) {
            resolve(res?.data.content)
          }
        }).catch(e => {
          reject(e)
        })
      })
    },

    /**
     * set Customer Pricing Tier Module: Get Customer Pricing Tier
     */
    async updateCustomerPricingTier( data ) {
      return new Promise((resolve, reject) => {
        this.$request.post({
          url: this.$apis.creditAssociationsUpdatePricingTier,
          data
        }).then(res => {
          if (res?.code === 1000) {
            this.$message.success('Update Successfully')
          } else {
            this.$message.error('Update Fail')
          }
          resolve( res?.code === 1000 )
        }).catch(e => {
          reject(e)
        })
      })
    },


    /**
     * 获取企业的状态说明文字
     * auditStatus:更新状态与后端约定,0:初始状态,1:审批中,2:拒绝,3:通过
     * enable:启用状态，true或false
     * active:注册状态，0:审核未通过,1:审核通过,2:注册未审核
     * @param {Object} company:企业数据对象
     */
    // 0 pending, 1 acrtive, 2 inactive, 3 flagged.
    getCompanyStatusText (company) {
      const { auditStatus, enable, active } = company
      let text = 'Active'
      if (active === 2) {
        text = 'Pending'
      } else if (active === 1) {
        if (enable) {
          if (auditStatus === 1) {
            // text = 'Update under review'
            text = 'Pending'
          } else if (auditStatus === 2) {
            // text = 'Update rejected'
            text = 'Active'
            text = 'Active'
          } else if (auditStatus === 3) {
            // text = 'Update passed'
            text = 'Active'
            text = 'Active'
          } else {
            text = 'Active'
          }
        } else {
          text = 'Deactivated'
        }
      } else {
        text = 'Rejected'
      }
      return text
    },

    getTagType (text) {
      let type = 'success'
      switch (text) {
        case 'Pending':
          type = 'info'
          break
        case 'Active':
          type = 'success'
          break
        case 'Deactivated':
          type = 'warning'
          break
        case 'Rejected' || 'Flagged':
          type = 'danger'
          break
        default:
          break
      }
      return type
    },

    /**
     * 退出登录
     */

    logout () {
      this.$request.post({
        url: this.$apis.logout
      })
      // 重置vuex的数据
      this.$store.dispatch('resetState')
      // 去除主题数据及用户名之外的其他所有缓存数据
      const language = localStorage.language || this.$i18n.locale
      const currentTheme = JSON.stringify(this.$store.state.currentTheme)
      const username = localStorage.username || ''
      localStorage.clear()
      sessionStorage.clear()
      // 缓存主题
      localStorage.currentTheme = currentTheme
      // 缓存语言
      localStorage.language = language
      // 如果用户勾选了记住用户，则缓存用户名
      localStorage.username = username
      // 重置路由
      resetRouter()
      // 跳转登录页面
      this.$router.replace({ name: 'login' })
    },

    /**
     * 显示或隐藏与状态操作有关的按钮
     * @param {Object} contract:当前订单数据
     * @param {String} button:按钮类型，如delete/cancel等
     */
    showOrHideButton ({ state, offline }, button) {
      const offlineList = offline ?? []
      switch (state) {
        // 没有状态
        case '':
        case null:
        case undefined:
          return ['submit', 'draft'].includes(button)
        // 草稿
        case 0:
          return ['edit', 'delete', 'submit', 'draft'].includes(button)
        // 待确认
        case 1:
          if (offlineList.length) {
            return ['accept', 'withdraw', 'cancel', 'verify', 'email', 'download'].includes(button)
          }
          return ['accept', 'withdraw', 'email', 'download'].includes(button)
        // 已确认
        case 2:
          if (offlineList.length) {
            return ['edit', 'verify', 'email', 'download'].includes(button)
          }
          return ['edit', 'cancel', 'verify', 'download'].includes(button)
        // 待取消
        case 3:
          return ['accept', 'download'].includes(button)
        // 待开票
        case 5:
          return ['edit', 'download'].includes(button)
        case 13:
          return ['download'].includes(button)
        // 其他
        default:
          return false
      }
    },
    getAvatarName (val) {
      const arr = val.split(' ')
      let name = ''
      arr.forEach(a => {
        name += a.charAt(0)
      })
      return name
    },
    numericByCompanySetting( value ) {

      const decimalPoints = this.currentCompanySettings?.decimalPoints ?? 4
      const roundingDecimal = this.currentCompanySettings?.roundingDecimal ?? 'ROUND_OFF'
      const power = Math.pow(10, decimalPoints)

      value = parseFloat( value ) * power

      if( roundingDecimal == 'CEILING' ) {
        value = Math.ceil( value );
      } else if( roundingDecimal == 'FLOOR' ) {
        value = Math.floor( value );
      } else {
        value = Math.round( value );
      }

      value = value / power

      return value.toFixed( decimalPoints )
    },
  }
}
